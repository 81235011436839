.github-links {
  float: right;
  .material-icons {
    border-radius: 4px;
    padding: 4px;
    font-size: 20px;
    &:hover {
      background-color: $mist;
    }
  }
}

.api-header {
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.api-body {

  .class-overview {
    position: relative;

    code-example {
        clear: left;
    }
  }

  .method-table, .option-table, .list-table {
    td > code {
      background-color: inherit;
      white-space: pre;
    }

    .with-github-links {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .github-links {
        a {
          color: $mediumgray;
          .material-icons:hover {
            background: none;
            color: $blue;
          }
        }
      }
    }

    h3 {
      margin: 6px 0;
      font-weight: bold;
      clear: left;
    }

    h4 {
      font-size: 14px;
      font-weight: bold;
      margin-top: 12px;
    }
  }

  .api-heading {
    padding: 5px 0;
    font-size: 16px;
    font-weight: bold;
  }

  .parameters-table {
    margin-top: 0;
    font-size: 14px;
    td:nth-child(1) {
      width: 20%;
    }
  }

  details.overloads {
    margin-left: -8px;

    summary {
      height: inherit;
      padding: 8px 12px;
      h4 {
        margin: 0;
        clear: left;
      }
    }
  }

  .from-constructor, .read-only-property, .write-only-property {
    font-style: italic;
    color: $blue;
  }

  .ngmodule-list {
    list-style: none;
    padding: 0;
  }

  .selector-list, .inherited-members-list {
    ul {
      padding: 0;
      li {
        list-style: none;
        margin-bottom: 12px;
      }
    }
  }

  .selector-list {
    li, a {
      font-weight: bold;
      i {
        font-weight: normal;
      }
    }
  }

  .member-name {
    font-weight: bold;
  }
}

.deprecated-api-item {
  text-decoration: line-through;
}
